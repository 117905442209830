<template>
  <div v-frag>
    <hero-title title="imprint" subtitle=""></hero-title>
    <v-container class="my-10">
      <v-row class="my-10">
        <v-col cols="12" class="text-center">
          <template v-for="(item, index) in companyDetails">
            <div :key="index" class="mb-6">
              <p class="mb-0">
                {{ $t(`${item.title}`) }}:
              </p>
              <p class="headline primary--text text--darken-3 mb-0">
                {{ item.value }}
              </p>
              <p v-if="item.extra">
                {{ $t('headquarter') }}: {{ item.extra }}
              </p>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroTitle from "../components/titles/HeroTitle";

export default {
  name: 'impresszum',
  components: {
    HeroTitle,
  },
  data() {
    return {
      companyDetails: [
        { title: 'nameOfTheCompany', value: 'TMX Mobile Solution Szerviz Szolgáltató Korlátolt Felelősségű Társaság' },
        { title: 'headquarterOfTheCompany', value: '2040 Budaörs, Kinizsi utca 2/B.' },
        /* { title: 'sitesOfTheCompany', value: '1085 Budapest, József körút 17. fszt. 2., 5465 Cserkeszőlő, Fürdő utca 16.' },*/
        { title: 'postalAddressOfTheCompany', value: '2040 Budaörs, Kinizsi utca 2/B.' },
        /*{ title: 'phoneNumberOfTheCompany', value: '+36-1/585-8381' },*/
        { title: 'telefaxNumberOfTheCompany', value: '+36-23/801-706' },
        { title: 'emailOfTheCompany', value: 'info@tmx.hu' },
        { title: 'VATNumberOfTheCompany', value: 'HU23059940' },
        { title: 'webhostDetails', value: 'TMX Mobile Solution Szerviz Szolgáltató Korlátolt Felelősségű Társaság', extra: '2040 Budaörs, Kinizsi utca 2/B.' },
      ],
    };
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
